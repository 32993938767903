<template>
  <div class="banner">
    <v-img :src="img" :height="height" />
    <v-container>
      <v-row justify="center">
        <v-col md="10" style="background-color: #fff;" class="pa-0">
          <v-row justify="center" no-gutters>
            <v-col cols="12" md="6" class="mb-6 mb-md-0 pa-8">
              <h3>{{ preTitle }}</h3>
              <h1 class="primary--text mb-4">
                {{ title }}
              </h1>
              <div v-html="text" />
              <v-btn
                small
                flat
                color="secondary"
                exact
                :to="{ name: 'donation' }"
                class="mt-6"
                elevation="0"
                v-if="this.$route.name != 'donation'"
                >Diğer Bağış Seçenekleri</v-btn
              >
            </v-col>
            <v-col cols="12" md="6" class="donation-box white--text">
              <v-tabs v-model="tab" background-color="secondary" dark grow>
                <v-tab>
                  ONLINE BAĞIŞ
                </v-tab>
                <v-tab>
                  HAVALE/EFT
                </v-tab>
              </v-tabs>
              <v-tabs-items
                v-model="tab"
                style="background-color: transparent"
                class="pa-6"
              >
                <v-tab-item>
                  <v-radio-group dark v-model="recurring" row>
                    <v-radio label="Tek Seferlik" :value="false" />
                    <v-radio label="Aylık Düzenli" :value="true" />
                  </v-radio-group>
                  <v-select
                    v-model="campaignId"
                    :items="donations"
                    item-text="name"
                    item-value="campaignId"
                    label="Bağış Türü"
                    solo
                    @change="onChange"
                  ></v-select>
                  <v-text-field solo v-model="amount" append-icon="₺" />
                  <v-checkbox v-model="confirm" dark>
                    <template v-slot:label>
                      <v-dialog v-model="confirmDialog" width="600">
                        <template v-slot:activator="{ on, attrs }">
                          <div>
                            <a
                              href="#"
                              class="white--text text-decoration-none"
                              v-bind="attrs"
                              v-on="on"
                              >Bağışçı onay metnini</a
                            >
                            okudum ve anladım
                          </div>
                        </template>
                        <v-card>
                          <v-card-title
                            >Kişisel Verilere İlişkin Bağışçı Onay
                            Metni</v-card-title
                          >
                          <v-card-text>
                            Bağışçı, Kişisel Verilerin Korunmasına İlişkin
                            Aydınlatma Metni’ni okuyup anlamıştır ve kendisinin
                            sunmuş olduğu ve diğer hukuki yollarla elde edilmiş
                            olan kişisel verilerinin (ör: isim, soy isimi,
                            e-posta adresi, telefon numarası vs.), KAHEV
                            tarafından bağışçı ile bağış talep etmek amacıyla
                            (telefon vasıtasıyla, SMS, e-posta gönderilerek veya
                            diğer yollarla) irtibat kurmak, bağışçılara ilişkin
                            bağışçı ilişkileri yönetimi ve kaynak yaratımı (ürün
                            satışı da dahil olmak üzere) analizi yapmak, bağışçı
                            profillerini daha iyi anlayarak KAHEV’e kaynak
                            yaratımını (bağış ve ürün satışı dahil olmak üzere)
                            artırmak, KAHEV ürünlerinin pazarlamasını yapmak
                            (nikah ürünleri, KAHEV logolu ürünler ve diğer
                            ürünler), KAHEV ürünlerine ilişkin olarak elektronik
                            veya diğer yollarla bilgilendirme yapmak, ve iyi
                            örnek teşkil etmesi amacıyla bağışçıların web
                            sitesinde ve diğer mecralarda (yapmış olduğu yardım
                            ve bağış belirtilebilir veya belirtilmeyebilir)
                            yayınlanması amaçlarıyla işlenmesini kabul
                            etmektedir.
                          </v-card-text>
                          <v-divider />
                          <v-card-actions>
                            <v-spacer />
                            <v-btn
                              color="secondary"
                              text
                              @click="confirmDialog = false"
                              >Kapat</v-btn
                            >
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                    </template>
                  </v-checkbox>

                  <v-btn color="secondary" elevation="0" @click="makeDonation"
                    >Bağış Yap</v-btn
                  >
                </v-tab-item>
                <v-tab-item>
                  <p>
                    Bize destek olmak için banka hesabımıza havale yapabilir
                    veya düzenli bağış talimatı verebilirsiniz.
                  </p>
                  <p>
                    <strong>Alıcı:</strong> Kadın Hekimler Eğitime Destek Vakfı
                    <br />
                    <strong>QNB Finansbank IBAN:</strong> TR06 0011 1000 0000 0082 8954 36 <br/>
                    <strong>Garanti Bankası IBAN:</strong> TR70 0006 2000 8730 0006 2936 94 <br/>
                    <strong>Ziraat Bankası IBAN:</strong> TR34 0001 0001 1797 6977 1750 01
                  </p>

                  <h3>Dolar ($) Hesabı</h3>
                  <p>
                    <strong>Alıcı adı:</strong> Kadın Hekimler Eğitime Destek
                    Vakfı (KAHEV) <br />
                    <strong>IBAN:</strong> TR39 0011 1000 0000 0094 7962 57
                    <br />
                    <strong>Banka:</strong> QNB Finansbank <br />
                    <strong>Şube:</strong> Eskişehir <br />
                    <strong>Şube Kodu:</strong> 260 <br />
                    <strong>Swift Kodu:</strong> FNNBTRISESK
                  </p>

                  <h3>Euro (€) Hesabı</h3>
                  <p>
                    <strong>Alıcı adı:</strong> Kadın Hekimler Eğitime Destek
                    Vakfı (KAHEV) <br />
                    <strong>IBAN:</strong> TR93 0011 1000 0000 0092 0432 01
                    <br />
                    <strong>Banka:</strong> QNB Finansbank <br />
                    <strong>Şube:</strong> Eskişehir <br />
                    <strong>Şube Kodu:</strong> 260 <br />
                    <strong>Swift Kodu:</strong> FNNBTRISESK
                  </p>
                </v-tab-item>
              </v-tabs-items>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  // metaInfo() {
  //   return {
  //     meta: [{ name: "Referrer", content: "origin" }],
  //     script: [
  //       {
  //         src: "https://ajax.googleapis.com/ajax/libs/jquery/3.6.3/jquery.min.js",
  //         async: true,
  //         defer: false
  //       },
  //       {
  //         src: "https://s.fonzip.com/js/fonzip.loader.js",
  //         async: true,
  //         defer: false
  //       }
  //     ]
  //   };
  // },

  data: function() {
    return {
      tab: null,
      confirm: false,
      confirmDialog: false,
      img: require("@/assets/banner-donation.jpg"),
      preTitle: "Desteklerinizle",
      title: "Daha Güçlüyüz!",
      text: `<p>
                KAHEV olarak ülkemizin aydınlık geleceği çocuklarımız ve
                gençlerimiz için üzerimize düşen sorumlulukların bilincinde, üye
                ve gönüllülerimizin desteğiyle daha da büyümeyi ve
                yepyeni projelerle daha çok kişinin elinden tutmayı
                hedefliyoruz.
              </p>
              <p>Siz de bize katılın!</p>`,
      amount: 0,
      recurring: false,
      campaignId: "",
      donations: [
        // {
        //   name: "Fidye",
        //   campaignId: "k81MXAYuzEeTnkhjkU5kNdKw50hPhkAlD5kWSSGtgoo="
        // },
        // {
        //   name: "Zekat",
        //   campaignId: "XqU3OBkTNFK+oO32KYfxQ/IyouhS5W2VhtdUU1wnuKA="
        // },
        // {
        //   name: "Fitre",
        //   campaignId: "tP5B9Vo8GU/ZpIwmVGQGbYK71o7XgV+GBXqRP6Ye+yE="
        // },
        {
          name: "Genel Bağış",
          campaignId: "f/g/8WShpthiLVF/L2961UYGuEgx9RIawY4ciYUaLUE="
        },
        {
          name: "Burs Bağışı",
          campaignId: "zoCPVEuvu2jCAo5mFh3qnBgLfBoH8AWYOSzjzgkuGAU="
        },
        // {
        //   name: "KAHEV Üyelik Aidatı",
        //   campaignId: "a/Lo8rLo9HvhJRmnPhd7wHSx5tPS6cOj72vnvM35OGs="
        // },
        // {
        //   name: "Geleceğimiz Yanmasın",
        //   campaignId: "WtlVjWY+7lcF4VW7dTeZ1w889/v0xRIkOwCRc39ujYo="
        // },
        // {
        //   name: "Doktorlar Eğitim İçin Söylüyor Bağışı",
        //   campaignId: "mbGSNSu+2E6tEfPjhF/++5b7JIeakIQfY3xoZS5S55U="
        // },
        {
          name: "VEFA Fonu",
          campaignId: "7kY+Bu6vULMbJrpL8aJ347NFKddCab174FxSy2i+Y+s="
        },
        {
          name: "Afet Fonu Bağışı",
          campaignId: "HnIpK26Y2rbJxw02cAYcikYJN6cFwYH4briZtFg6N+Y="
        },
        // {
        //   name: "Dr. Ekrem Karakaya Burs Fonu",
        //   campaignId: "TWiHyJ83vFDIIe1WTgXI+TqbObnxtksJ7jfkN9pIhq8="
        // },
        {
          name: "Emanetiniz, Emanetimizdir!",
          campaignId: "bns4+eYCcsbVb1Ln6aO1W2vEnpr3DqbmRNvNrqwriBw="
        },
        {
          name: "Stetoskop Bağışı",
          campaignId: "nqjJTDgfX2bI4p1nHy2VmeFb0sxbDSdAjZJ9qzmXznY="
        },
        {
          name: "Amigurumi Bağışı",
          campaignId: "BtmSkgQI61wyYNg9j+2H7TzUhy0lxbghIkpDm4WRf0E="
        },
        // {
        //   name: "Alime Eserdağ Fonu",
        //   campaignId: "THCM9AE7Ai6AQz1/x9QkKj6TjJ8cG7QUm1w68PS1zFU="
        // },
        {
          name: "Doktor Babalar Burs Fonu Bağışı",
          campaignId: "r9ia33p8EsG8qJ90chiAw+NI2jjNETHR+hcxJfNkNL0="
        },
        // {
        //   name: "Emdate Bağışı",
        //   campaignId: "KkqmpMRVpsmQ1NM6NuMdg2Zv1uiQbHxqjURiFckLmZk="
        // },
        // {
        //   name: "Hobi Bağışı",
        //   campaignId: "d9UJmvpRlQHyR573/IM/jqKzVYVDD/TOaseY03OKLUQ="
        // },
        {
          name: "Okul Desteği Bağışı",
          campaignId: "mvIohoTSPqSlxAZSJwg1M4Qx1D4+oG5MA6vv6a8uvqo="
        }
        // ,{
        //   name: "Rotary Bağışı",
        //   campaignId: "ghOFxFfwpsC+uGnrbNmozkPEPiUcfZtwog4p+rRu7Ik="
        // }
      ]
    };
  },

  computed: {
    height() {
      switch (this.$vuetify.breakpoint.name) {
        case "sm":
          return 300;
        case "md":
          return 600;
        case "lg":
          return 600;
        case "xl":
          return 600;
        default:
          return 300;
      }
    }
  },

  methods: {
    onChange() {
      console.log(this.campaignId);
    },

    makeDonation() {
      window.fz.bagis({
        // eslint-disable-next-line @typescript-eslint/camelcase
        api_key: "asAUkcL91xjY08jGpNBRFEu5zwp9yVsrZ9rISGLQSUM=",
        amount: this.amount.toString(),
        recurring: this.recurring,
        campaign: this.campaignId,
        onload: function() {
          console.log("fz loaded!");
        },
        success: function(data) {
          console.log(data);
        },
        error: function(data) {
          console.log(data);
        },
        close: function(result) {
          console.log(result);
        }
      });
    }
  },

  mounted() {
    this.campaignId = this.donations[0].campaignId;
  }
};
</script>

<style lang="scss" scoped>
.banner {
  overflow: hidden;
  position: relative;

  .container {
    padding: 0;
  }

  @media #{map-get($display-breakpoints, "md-and-up")} {
    .container {
      position: relative;
      top: -80px;
    }
  }

  .donation-box {
    background-color: $color1;
    background-image: url("../../assets/hand-heart.svg");
    background-repeat: no-repeat;
    background-size: 75%;
    background-position: right 30px;
  }
}
</style>
