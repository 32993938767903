








import { Component, Vue } from "vue-property-decorator";
import Banner from "./Banner.vue";

@Component({
  metaInfo: {
    title: "Bağış Yap"
  },
  components: {
    Banner
  }
})
export default class Index extends Vue {}
